import React, { useState } from "react"
import styled from "styled-components"
import { graphql, Link, navigate } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useFlexSearch } from 'react-use-flexsearch'
import { Formik, Form, Field } from 'formik'

import Layout from "../layouts"
import Seo from "../components/seo"
import { BreadCrumb, FlexWrap, RightWrap, LeftWrap, Section, SectionPageTitle, SubTitle } from "../components/Section"
import CalendarIcon from "../components/Icons/CalendarIcon"
import UserIcon from "../components/Icons/UserIcon";
import LineArrowRight from "../components/Icons/LineArrowRight";
import Content, { HTMLContent } from '../components/Content';
import generateHTML from '../utils/generateHTML';
import { extractStaticUrlStr } from "../utils/string";

const BlogWraper = styled.div`
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    .created {
      display: flex;
      align-items: center;
      p {
        color: #E43D3C;
        margin: 0 20px 0 10px;
      }
    }
    .content-wrap {
      padding: 0 40px 80px;
    }
`

const SideBar = styled.div`
  width: 350px;
  margin-left: 50px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  padding: 40px 25px;

  input {
    width: 100%;
    border: 1px solid #D0D0D0;
    padding: 15px 15px 18px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    border-radius: 8px;
    line-height: 1;
    margin-bottom: 30px;
  }
  form {
    button {
      width: 100%;
      .btn {
        width: 100%;
      }
    }
  }
  @media(max-width: 480px) {
    width: 100%;
    margin-left: 0px;
  }
`
const Category = styled.div`
	span {
		color: #E43D3C;
		margin-right: 10px;
	}
`

const CategoryItem = styled.div`
  cursor: pointer;

  color: #938888;
  &:hover {
      color: #E43D3C; 
  }
  &.active {
      color: #E43D3C;
  }
`

const BlogWrap = styled.div`
  margin-bottom: 30px;
  
  .title {
		font-size: 21px;
		line-height: 31px;
		font-weight: 600;
    margin-bottom: 0;
	}
	.feature {
		color: #E43D3C;
		font-family: Eina03-SemiBold;
	}
	.gatsby-image-wrapper {
		border-radius: 6px;
    margin: 20px 0;
	}
`

const MoreCategory = styled.div`
  margin: 10px 0 20px;
  color: #E43D3C;
  display: flex;
  align-items: center;

  svg {
    stroke: #E43D3C;
    width: 15px;
    margin-left: 5px;
  }
  &:hover {
    cursor: pointer;
    svg {
      margin-left: 8px;
    }
  }
`

const SearchForm = styled.div`
  position: relative;
  z-index: 10;
  margin-bottom: 20px;

  input {
		width: 100%;
		border: 1px solid #D0D0D0;
		padding: 15px;
		box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
		border-radius: 8px;
		line-height: 1;
		
	}
`

const SearchResults = styled.div`
    position: absolute;
    display: ${props => props.display};
    padding: 20px;
    width: 300px;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    background: #ffffff;
    right: 0;
    top: 55px;
    z-index: 20;
    border-radius: 6px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);

    li {
      list-style: none;

      a {
        color: #000000;

        &:hover {
          text-decoration: underline;
          color: #E43D3C; 
        }
      }
    }
  }
`

const BlogTemplate = ({ data, location }) => {
  const [categoryLimit, setCategoryLimit] = useState(3)
  const pageData = data.contentfulBlog
  const blogs = data.allContentfulBlog.edges
  const PostContent = HTMLContent || Content;
  const categories = data.allContentfulBlogCategory.edges

  const [query, setQuery] = useState(null);
  const index = data.localSearchPages.index
  const store = data.localSearchPages.store

  const results = useFlexSearch(query, index, store)
  return (
    <Layout footerCta location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <BreadCrumb position="relative">
        <div className="container">
          <Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
          <span>Blog</span>
        </div>
      </BreadCrumb>
      <Section bgColor="#F7F5F5" mb="70px" mmb="40px">
        <div className="container">
          <FlexWrap>
            <LeftWrap width="calc(100% - 400px)">
              <BlogWraper>
                <GatsbyImage image={getImage(pageData.image.gatsbyImageData)} alt={pageData.title} />
                <div className="content-wrap">
                  <SectionPageTitle textAlign="left" color="#574C4C" mt="40px" mb="15px">
                    {pageData.title}
                  </SectionPageTitle>
                  <div className="created">
                    <CalendarIcon />
                    <p>{pageData.createdAt}</p>
                    <UserIcon />
                    <p>{pageData.author.name}</p>
                  </div>
                  <PostContent
                    content={generateHTML(pageData.content.childMarkdownRemark.html)}
                  />
                </div>
              </BlogWraper>
            </LeftWrap>
            <RightWrap width="400px">
              <SideBar>
                <SearchForm>
                  <Formik
                    initialValues={{ query: '' }}
                    onSubmit={(values, { setSubmitting }) => {
                      setQuery(values.query)
                      setSubmitting(false)
                    }}
                  >
                    <Form>
                      <Field name="query" placeholder="Search Blog ..." />
                    </Form>
                  </Formik>
                  <SearchResults display={query !== null ? "block" : "none"}>
                    {results?.length > 0 ? results.map(result => (
                      <Link to={result.path}>{result.title}</Link>
                    )) : (
                      <p>There is no blogs with that search key</p>
                    )}
                  </SearchResults>
                </SearchForm>
                <SubTitle mt="0" mb="15px" textAlign="left">
                  Categories
                </SubTitle>
                {categories.map((item, i) => {
                  if (i < categoryLimit) {
                    return (
                      <Category>
                        <CategoryItem
                          key={i}
                          onClick={() => navigate("/blog", { state: { category: item.node.name }})}
                        >
                          <span>-</span> {item.node.name}
                        </CategoryItem>
                      </Category>
                    )
                  } else {
                    return true;
                  }
                })}
                {categoryLimit < categories.length && (
                  <MoreCategory onClick={() => setCategoryLimit(categoryLimit + 3)}>
                    More categories <LineArrowRight />
                  </MoreCategory>
                )}
                <SubTitle mt="15px" mb="15px" textAlign="left">
                  Recent Posts
                </SubTitle>
                {
                  blogs.map((item, i) => {
                    if (i < 3) {
                      return (
                        <BlogWrap key={i}>
                          <Link to={item.node.url}>
                            <h3 class="title">{item.node.title}</h3>
                          </Link>
                          <FlexWrap alignItems="center" justifyContent="center">
                            <LeftWrap width="100%">
                              <Link to={item.node.url}>
                                <GatsbyImage image={getImage(item.node.image.gatsbyImageData)} alt={item.node.title} />
                              </Link>
                            </LeftWrap>
                          </FlexWrap>
                        </BlogWrap>
                      )
                    } else {
                      return true;
                    }
                  })
                }
                {/* <SubTitle mt="15px" mb="5px" textAlign="left">Subscribe to our blog</SubTitle>
                <SectionDescription textAlign="left" mb="25px">
                  Join our newsletter to review the latest blog update in your inbox
                </SectionDescription>
                <form>
                  <input type="email" id="email" placeholder="Your email address" />
                  <button type="submit">
                    <PrimaryLinkButton text="Subscribe to our blog" icon={<LineArrowRight />} />
                  </button>
                </form> */}

              </SideBar>
            </RightWrap>
          </FlexWrap>
        </div>
      </Section>
    </Layout>
  )
}


export default BlogTemplate

export const pageQuery = graphql`
  query BlogTemplateQuery($id: String!) {
    contentfulBlog(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      title
      image {
        gatsbyImageData(quality: 80)
      }
      createdAt(fromNow: true)
      author {
        name
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulBlog(sort: {fields: createdAt}) {
      edges {
        node {
          image {
            gatsbyImageData(quality: 80)
          }
          title
          createdAt(fromNow: true)
          url
        }
      }
    }
    allContentfulBlogCategory {
      edges {
        node {
          name
          url
        }
      }
    }
    localSearchPages {
      index
      store
    }
  }
`